/*
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-21 18:34:38
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-08-14 16:26:42
 * @FilePath: /lcwpcvue/src/api/commen/chatApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

/**
* 获取聊天列表
* @url Wschat/chatList   v2/WschatV2/getchatList
* @param {} params 
* @return 
*/
export function getChatList(params) {
  return request({
    // url: 'Wschat/chatList',
    url: 'v2/WschatV2/getchatList',
    method: 'get',
    params: params
  })
}

/**
* 搜索聊天
* @url v2/WschatV2/getChatPerson
* @param {} params 
* @return 
*/
export function searchChatList(params) {
  return request({
    // url: 'Wschat/chatList',
    url: 'v2/WschatV2/getChatPerson',
    method: 'get',
    params: params
  })
}




/**
* 获取聊天信息
* @url member/userResult
* @param {to_uid//聊天对象id} params 
* @return 
*/

export function getChatUserInfo(params) {
  return request({
    url: 'member/userResult',
    method: 'post',
    data: params
  })
}

/**
* 获取交换信息状态
* @url Chat/chatBtnStatus
* @method GET
* @param {to_uid//对方id} params 
* @return 
*/
export function getChatChangedInf(params) {
  return request({
    url: 'Chat/chatBtnStatus',
    method: 'get',
    params: params
  })
}
/**
* 改变交换状态
* @url Chat/exchangeStatus
* @method POST
* @param {to_uid: this.uid,type: e.type,status: e.status == 4 ? 2 : e.status} params 
* @return 
*/

export function changeWxPhStatus(params) {
  return request({
    url: 'Chat/exchangeStatus',
    method: 'post',
    data: params
  })
}




/**
* 获取用户的微信号和手机号
* @url Wschat/telInfo
* @method POST
* @param {uid,per_uid:,per_name:,com_alias: ,type: } params 
* @return 
*/
export function getChatWxnoPhnoInfo(params) {
  return request({
    url: 'Wschat/telInfo',
    method: 'post',
    data: params
  })
}



/**
* 获取聊天历史记录
* @url Wschat/msglist
* @param {to_uid//聊天对象,page//页码} params 
* @return 
*/
export function getChatHistoryList(params) {
  return request({
    url: 'Wschat/msglist',
    method: 'get',
    params: params
  })
}


/**
* 首次聊天打招呼 ||  职位描述模版
* @url applets/lookother
* @param {job_id: '',type} params 
* @return 
*/
export function fistChatToMsg(params) {
  return request({
    url: 'applets/lookother',
    method: 'get',
    params: params
  })
}


/**
* 获取常用语列表
* @url Chat/chatOftenList
* @param {} params 
* @return 
*/
export function getChatCyyList() {
  return request({
    url: 'Chat/chatOftenList',
    method: 'post'
  })
}

/**
* 添加常用语
* @url Chat/chatOftenAdd
* @param {content:''} params 
* @return 
*/
export function addChatCyyList(params) {
  return request({
    url: 'Chat/chatOftenAdd',
    method: 'post',
    data: params
  })
}

/**
* 编辑常用语
* @url Chat/chatOftenEdit
* @param {id: "",content: ""} params 
* @return 
*/
export function editChatCyyList(params) {
  return request({
    url: 'Chat/chatOftenEdit',
    method: 'post',
    data: params
  })
}

/**
* 删除常用语
* @url Chat/chatOftenDel
* @param {ofen_id::''} params 
* @return 
*/

export function delChatCyyList(params) {
  return request({
    url: 'Chat/chatOftenDel',
    method: 'post',
    data: params
  })
}

/**
* 检测微信
* @url Chat/checkSetUp
* @param {to_uid::''} params 
* @return 
*/

export function checkWxNumber(params) {
  return request({
    url: 'Chat/checkSetUp',
    method: 'post',
    data: params
  })
}


/**
* 发送消息
* @url Wschat/sendmsg(废弃)   v2/WschatV2/sendMsg
* @param {type: type,content: msgstr,to_uid: that.uid,chat_type: 'normal',agree: agree,job_id: that.job_id ? that.job_id : '',new_content: new_content} params 
* @return 
*/

export function sedChatMsg(params) {
  return request({
    url: 'v3/WschatV2/sendMsg',
    method: 'post',
    data: params
  })
}

/**
* 获取可发送面试邀约的职位
* @url Chat/comJobs
* @param {uid::''} params 
* @return 
*/
export function getChatJobList(params) {
  return request({
    url: 'Chat/comJobs',
    method: 'post',
    data: params
  })
}

/**
* socket绑定
* @url Wschat/binding
* @param {cid,pageurl} params 
* @return 
*/
export function bindSocketCid(params) {
  return request({
    url: 'Wschat/binding',
    method: 'post',
    data: params
  })
}


/**
* 判断直聊
* @url Chat/isCanChat
* @param {to_uid} params 
* @return 
*/
export function checkIsCanChat(params) {
  return request({
    url: 'Chat/isCanChat',
    method: 'post',
    data: params,
  })
}


/**
* 判断是否拉黑
* @url Chat/checkSetUp
* @param {to_uid} params 
* @return 
*/
export function checkIsChatBlack(params) {
  return request({
    url: 'Chat/checkSetUp',
    method: 'post',
    data: params,
  })
}


/**
* 已读消息
* @url Wschat/resdMsg
* @param {to_uid} params 
* @return 
*/
export function postMsgRead(params) {
  return request({
    url: 'Wschat/resdMsg',
    method: 'post',
    data: params,
  })
}


/**
* 检查有没有绑定微信号
* @url Wschat/telInfo
* @param {uid,per_uid,per_name,com_alias,type} params 
* @return 
*/
export function checkHasWxnum(params) {
  return request({
    url: 'Wschat/telInfo',
    method: 'post',
    data: params,
  })
}


/**
* 发送面试邀请
* @url Interview/interview
* @param {uid,address,intertime,jobid,content,linkman,linktel} params 
* @return 
*/
export function sendInterview(params) {
  return request({
    url: 'Interview/interview',
    method: 'post',
    data: params,
  })
}

/**
* 获取系统消息列表
* @url Notices/sysNoticeList
* @param {page} params 
* @return 
*/
export function getSysMsgList(params) {
  return request({
    url: 'Notices/sysNoticeList',
    method: 'post',
    data: params,
  })
}

/**
* 消息助手已读
* @url Notices/sysPushRead
* @param {page} params 
* @return 
*/
export function sysMsgRead(params) {
  return request({
    url: 'Notices/sysPushRead',
    method: 'post',
    data: params,
  })
}

/* Notices/noticeList */

/**
* 点进去的系统消息
* @url Notices/noticeList
* @param {page} params 
* @return 
*/

export function getSysSonMsg(params) {
  return request({
    url: 'Notices/noticeList',
    method: 'post',
    data: params,
  })
}
/**
 * 待面试同意或拒绝时发送消息
 * @param params
 */
export function chatLog(params) {
  return request({
    url: 'Chat/chatLog',
    method: 'post',
    data: params,
  })
}

/**
* 系统消息已读
* @url Notices/normalNotice
* @param {page} params 
* @return 
*/

export function sonMsgRead(params) {
  return request({
    url: 'Notices/normalNotice',
    method: 'post',
    data: params,
  })
}

/**
* 消息删除  type=1系统    type=2 助手
* @url Notices/delNotices
* @param {type: 2,id: id} params 
* @return 
*/

export function delMsg(params) {
  return request({
    url: 'Notices/delNotices',
    method: 'post',
    data: params,
  })
}


/**
* 撤回聊天消息
* @url Chat/delChat
* @param {id,to_uid} params 
* @return 
*/

export function withdrawMsg(params) {
  return request({
    url: 'Chat/delChat',
    method: 'post',
    data: params,
  })
}

/**
* 获取备注标签
* @url chat/remarkslist
* @param {} params 
* @return 
*/
export function getRemarkTagList(params) {
  return request({
    url: 'chat/remarkslist',
    method: 'get',
    params: params
  })
}

/**
* 添加备注标签
* @url chat/remarksadd
* @param {} params 
* @return 
*/
export function addRemarksTag(params) {
  return request({
    url: 'chat/remarksadd',
    method: 'post',
    data: params,
  })
}


/**
* 保存备注
* @url chat/remarks
* @param {} params 
* @return 
*/

export function saveRemarks(params) {
  return request({
    url: 'chat/remarks',
    method: 'post',
    data: params,
  })
}






