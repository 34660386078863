<template>
  <div class="company-inf-page">
    <div class="top-zk-div"></div>
    <div style="background-color: white; width: 100%">
      <div class="company-detail-top-view">
        <div class="top-left-view">
          <div class="bas-in-view">
            <img class="com-avatar" :src="companyInf.logo" alt="" />
            <div class="name-type-hot-view">
              <div class="name-st">
                <span class="name">{{ companyInf.name }}</span>
                <img
                  class="st-img"
                  v-if="companyInf.yyzz_status == 1"
                  :src="companyInf.yyzz_status_icon"
                  alt=""
                />
              </div>
              <div class="com-type">
                <span>{{ companyInf.hy_name }}</span>
                <span v-show="companyInf.company_num">
                  <el-divider direction="vertical"></el-divider>
                  <span>{{ companyInf.company_num }}</span>
                </span>
                <span v-show="companyInf.area">
                  <el-divider direction="vertical"></el-divider>
                  <span>{{ companyInf.area }}㎡</span>
                </span>
              </div>
              <div class="hot-num-view" v-if="comJobList.length > 0">
                <img src="@/assets/com_detail_hot.png" alt="" />
                <span>正在热招<label>{{ comJobList.length }}个</label>{{comJobList[0].name}}等相关职位</span>
              </div>
            </div>
          </div>
          <div class="company-tag-view">
            <label v-for="(item, index) in companyInf.welfare" :key="index">
              <span class="tag-item">{{ item }}</span>
            </label>
          </div>
        </div>

        <div class="top-right-btn-view" v-if="!isYl">
          <el-button type="primary" @click="toChatPage"
            ><img
              class="ll-btn-img"
              src="@/assets/company_lyl_img.png"
              alt=""
            />
            聊一聊</el-button
          >
          <div class="bot-act-btn-view">
            <div
              class="items"
              :class="isShield == 1 ? 'items-ispb' : ''"
              @click="shieldingCompany"
            >
              <!-- <img src="@/assets/company_pb_img.png" alt="" /> -->
              <i class="el-icon-remove-outline"></i>
              <span>{{ isShield == 1 ? "已屏蔽" : "屏蔽" }}</span>
            </div>
            <div class="items" @click="collectionCompany">
              <label v-if="isCollection == 0">
                <img src="@/assets/company_sc_img.png" alt="" />
                <span style="color: #fe6002">关注</span>
              </label>
              <label v-if="isCollection == 1">
                <img src="@/assets/company/companyinf_isgz_img.png" alt="" />
                <span style="color: #fe6002">已关注</span></label
              >
            </div>
            <el-popover
              placement="bottom"
              title="分享"
              width="200"
              trigger="click"
              @show="popoverShowFFun"
            >
              <div class="items" slot="reference">
                <img src="@/assets/jobinf_share_img.png" alt="" />
                <span style="color: #fe6002">分享</span>
              </div>
              <div class="share-ewm-img-view">
                <img :src="shareQCodeImg" alt="" />
              </div>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <div class="company-detail-min-view">
      <div class="min-left-view">
        <div
          class="com-sty dpts-view"
          v-show="companyInf.content || videoCover || getShowPic.length > 0"
        >
          <div v-show="companyInf.content">
            <div class="title-one">公司介绍</div>
            <div class="title-sri">{{ companyInf.content }}</div>
          </div>
          <div v-show="videoCover || getShowPic.length > 0">
            <div class="title-one" style="margin-top: 40px">公司相册</div>
            <div class="bot-scro-view">
              <div
                class="aleft-div slide-left-right-div"
                @click.stop="imgPage('up')"
              >
                <i class="el-icon-arrow-left icon-arrowhead-left"></i>
              </div>
              <div
                v-show="imgIdx === 1 && video"
                @click.stop="isOpenVideo = true"
              >
                <img
                  src="../../src/assets/transfer_spplay.png"
                  class="video-cover-img"
                  style="width: 50px; height: auto"
                />
                <img class="scro-img" :src="videoCover" />
              </div>
              <label v-for="(item, idx) in getShowPic" :key="idx">
                <div>
                  <div class="scro-img">
                    <el-image
                      style="width: 100%; height: 100%"
                      :src="item"
                      :preview-src-list="showList"
                    >
                    </el-image>
                  </div>
                </div>
              </label>
              <div
                class="towards-right-div slide-left-right-div"
                @click.stop="imgPage('dw')"
              >
                <i class="el-icon-arrow-right icon-arrowhead-right"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="com-sty dpts-view">
          <div class="title-one">公司地址</div>
          <div class="title-tw-img-view">
            <img src="@/assets/jobinf_addr_img.png" alt="" />
            <span>{{ companyInf.address }}</span>
          </div>
          <zczmap
            v-if="companyInf.x"
            :lat="companyInf.y"
            :lng="companyInf.x"
            idname="tramap"
            :mapwidth="'810'"
            :mapheight="'335'"
          ></zczmap>
        </div>

        <div class="com-sty min-ju-view">
          <div class="lc-ts-view">
            <img src="../assets/jobinf_minjb_img.png" alt="" />
            <span>猎宠网安全提示：</span>
          </div>
          <div class="jb-text">
            求职中如遇到招聘方存在虚假信息，收取费用，扣押证件，或其他违法情形，请立即<a
              @click="toReport"
              >举报！</a
            >
          </div>
        </div>

        <div class="com-sty dpts-view">
          <div class="title-one">招聘职位</div>
          <div class="com-joblist-view">
            <otherjobcard :jobArr="comJobList"></otherjobcard>
          </div>
        </div>
      </div>
      <div class="min-right-view">
        <loginview v-show="!isLogin" :pathname="'job'"></loginview>

        <div class="jobdetail-tjjob-view">
          <div class="tj-title-view">
            <img class="tj-title-img" src="@/assets/index_jxgw.png" alt="" />
            <span class="tj-view">推荐职位</span>
          </div>
          <div class="tj-list">
            <label v-for="item in comJobList" :key="item.id">
              <div class="tjitem" @click.stop="toJobInfPager(item.id,item.part)">
                <div class="tj-top-view">
                  <span class="tj-t1">{{ item.name }}<span v-show="item.part == 1" class="job-tag-s">兼职</span></span>
                  <span
                    class="spar-gz"
                    v-if="item.part != 1 && item.minsalary != 0"
                    >￥{{ item.minsalary }}-{{ item.maxsalary }}</span
                  >
                  <span
                    class="spar-gz"
                    v-else-if="item.part != 1 && item.minsalary == 0"
                    >面议</span
                  >
                  <span class="spar-gz" v-else-if="item.part == 1">{{
                    item.salary
                  }}</span>
                </div>
                <div class="com-name">{{ item.com_name }}</div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>

    <reportdialog
      :reportDialogShow="reportDialogShow"
      :dlTitle="'举报公司'"
      :rpUid="uid"
      @closeReportDialog="closeReportDialog"
    ></reportdialog>
    <div class="video-open">
      <el-dialog
        :visible.sync="isOpenVideo"
        :show-close="false"
        :before-close="closeVideo"
      >
        <div style="height: 650px; display: flex; justify-content: center">
          <video
            controls
            style="max-height: 650px; min-width: 400px"
            ref="videoPlayer"
          >
            <source :src="video" type="video/mp4" />
            您的浏览器不支持 video 标签。
          </video>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import moduleName from 'module';
import { getCompanyDetailInfo } from "@/api/compe/companyInf.js";
import {
  checkUserIsCanChat,
  shieldingCompany,
  collectionCompany,
} from "@/api/indiviuser/jobInfoPage";
import { checkIsLahe } from "@/api/commen/aboutTalentsApi.js";
import { userPcApi } from "@/api/commen/setApi.js";
import loginview from "@/components/loginWthiPage.vue";
import otherjobcard from "@/components/comUser/jobCardInCompanyInfPage.vue";
import reportdialog from "@/components/reportDialog.vue";

export default {
  components: {
    loginview,
    otherjobcard,
    reportdialog,
  },
  data() {
    return {
      isOpenVideo: false,
      comJobList: [],
      companyInf: {},
      showList: [],
      video: "",
      videoCover: "",
      pics: ["1", "2", "3", "4", "5"],
      map: null,
      T: null,
      mapShow: true,
      uid: "",
      imgIdx: 1,
      isLogin: false,
      comJobLis: [],
      reportDialogShow: false,
      isYl: false,
      shareQCodeImg: "",
      isShield: "",
      isCollection: "",
    };
  },
  computed: {
    getShowPic() {
      console.log(this.imgIdx);
      if (this.imgIdx === 1) {
        return this.showList;
      } else if (this.imgIdx === 2) {
        return this.showList.slice(!this.video ? 5 : 4);
      } else if (this.imgIdx === 3) {
        return this.showList.slice(!this.video ? 10 : 9);
      }
      return this.showList;
    },
  },
  mounted() {
    // 初始化天地图
  },
  created() {
    this.isLogin = localStorage.token ? true : false;
    this.uid = this.$route.query.id;
    if (this.$route.query.isyl) {
      this.isYl = true;
    }
    this.getCompanyDetailInfo();
  },
  methods: {
    toJobInfPager(id,isPart) {
      if (isPart !== '1'){
        this.$router.push(`/jobinfopage?id=${id}`);
      }else {
        this.$router.push(`/parttimejobdetail?id=${id}`);
      }
    },
    closeVideo() {
      const video = this.$refs.videoPlayer;
      if (video) {
        video.pause();
        video.currentTime = 0; // 可选，将视频 currentTime 重置为 0
      }
      this.isOpenVideo = false;
    },
    imgPage(type) {
      console.log(type);
      if (type === "up") {
        if (this.imgIdx === 1) return;
        this.imgIdx--;
        this.getShowPic;
      } else if (type === "dw") {
        let subNum = this.video ? 1 : 0;
        if (this.imgIdx * 5 - subNum >= this.showList.length) return;
        this.imgIdx++;
        this.getShowPic;
      }
    },
    //获取公司信息
    getCompanyDetailInfo() {
      getCompanyDetailInfo({
        company_uid: this.uid,
      })
        .then((result) => {
          console.log("获取公司信息", result);
          document.title = result.data.company.name;

          this.companyInf = result.data.company;
          if (result.data.company_jobs){
            this.comJobList = result.data.company_jobs;
          }else {
            this.comJobList = [];
          }
          this.isShield = result.data.is_shield;
          this.isCollection = result.data.is_collection;
          let imgs = result.data.company.company_pic;
          imgs.forEach((im) => {
            if (im.type === "img") {
              this.showList.push(im.picurl);
            } else {
              this.video = im.picurl;
              this.videoCover = im.coverurl;
            }
          });
        })
        .catch(() => {});
    },
    /* 聊天 */
    toChatPage() {
      if (!this.isLogin) {
        this.$router.push(`/login`);
        return false;
      }
      checkUserIsCanChat()
        .then((result) => {
          console.log("ooopppooo", result);
          if (result.data.score > result.data.myscore || result.code == 102) {
            // 102简历未通过审核
            this.$message({
              message: result.msg,
              type: "warning",
            });
            // uni.showToast({
            //   title: res.msg,
            //   icon: "none",
            // });
            setTimeout(() => {
              this.$router.push(`/useredtresume`);
            }, 1500);
          } else {
            checkIsLahe({ to_uid: this.uid })
              .then((result) => {
                console.log("是否拉黑", result);
                if (result.data.hmd == 1) {
                  this.$confirm(
                    "您已将对方拉黑，确认要给他发送消息吗？",
                    "提示",
                    {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning",
                    }
                  )
                    .then(() => {
                      this.$router.push(`/chatpage?chatid=${this.uid}`);
                    })
                    .catch(() => {});
                } else {
                  if (result.data.to_hmd == 1) {
                    this.$confirm(
                      "对方已将你拉黑，您不能发送消息给TA!",
                      "提示",
                      {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                      }
                    )
                      .then(() => {})
                      .catch(() => {});
                  } else {
                    this.$router.push(`/chatpage?chatid=${this.uid}`);
                  }
                }
              })
              .catch(() => {});

            //判断拉黑
            // api.post({
            //   url: "/Chat/checkSetUp",
            //   data: {
            //     to_uid: this.uid,
            //   },
            //   success: (res) => {
            //     // console.log('获取设置信息', res);
            //     if (res.data.hmd == 1) {
            //       this.modalContenLh = "您已将对方拉黑，确认要给他发送消息吗？";
            //       this.modalLh = true;
            //       this.isLahei = 1;
            //     } else {
            //       if (res.data.to_hmd == 1) {
            //         this.modalContenLh = "对方已将你拉黑，您不能发送消息给TA!";
            //         this.modalLh = true;
            //         this.isLahei = 2;
            //       } else {
            //         uni.navigateTo({
            //           url: `/pagesA/chat/chat?uid=${this.uid}`,
            //         });
            //       }
            //     }
            //   },
            // });
          }
        })
        .catch(() => {});

      // /* 判断拉黑 */

      // })
      // .catch(() => {});
    },
    /* 屏蔽公司 */
    shieldingCompany() {
      if (!this.isLogin) {
        this.$router.push(`/login`);
        return false;
      }
      shieldingCompany({
        to_uid: this.uid,
      })
        .then((result) => {
          console.log("屏蔽公司", result);
          this.getCompanyDetailInfo();
          if (result.code == 200) {
            this.$message({
              message: result.msg,
              type: "success",
            });
          } else {
            this.$message({
              message: result.msg,
              type: "error",
            });
          }
        })
        .catch(() => {});
    },
    /* 收藏公司 */
    collectionCompany() {
      if (!this.isLogin) {
        this.$router.push(`/login`);
        return false;
      }
      collectionCompany({
        do_uid: this.uid,
        job_id: 0,
      })
        .then((result) => {
          this.getCompanyDetailInfo();

          console.log("收藏公司", result);
        })
        .catch(() => {});
    },
    /* 举报 */
    toReport() {
      this.reportDialogShow = true;
    },
    closeReportDialog() {
      this.reportDialogShow = false;
    },
    /* 分享 */
    popoverShowFFun() {
      console.log("显示");
      userPcApi({
        type: "createQrcode",
        exeType: "cominfo",
        id: this.uid,
      })
        .then((result) => {
          console.log("获取分享二维码", result);
          this.shareQCodeImg = result.data.img;
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.company-detail-top-view {
  display: flex;
  padding: 40px 0;
  justify-content: space-between;
  width: 1480px;
  margin: 0 auto;
}

.company-detail-top-view .top-left-view .bas-in-view {
  display: flex;
  align-items: center;
}

.company-detail-top-view .top-left-view .bas-in-view .com-avatar {
  width: 145px;
  height: 145px;
  border-radius: 6px;
}

.company-detail-top-view .top-left-view .bas-in-view .name-type-hot-view {
  margin-left: 24px;
}

.company-detail-top-view
  .top-left-view
  .bas-in-view
  .name-type-hot-view
  .name-st {
  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 36px;
  color: #191919;
  line-height: 42px;
}
.company-detail-top-view
  .top-left-view
  .bas-in-view
  .name-type-hot-view
  .name-st
  .st-img {
  width: 62px;
  height: 20px;
  margin-left: 24px;
}
.company-detail-top-view
  .top-left-view
  .bas-in-view
  .name-type-hot-view
  .com-type {
  font-weight: 400;
  font-size: 18px;
  color: #757575;
  line-height: 24px;
  margin-top: 24px;
}
.company-detail-top-view
  .top-left-view
  .bas-in-view
  .name-type-hot-view
  .hot-num-view {
  margin-top: 24px;

  font-weight: 400;
  font-size: 16px;
  color: #757575;
  line-height: 19px;
}
.company-detail-top-view
  .top-left-view
  .bas-in-view
  .name-type-hot-view
  .hot-num-view
  img {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}
.company-detail-top-view
  .top-left-view
  .bas-in-view
  .name-type-hot-view
  .hot-num-view
  span
  label {
  color: #fe6002;
}
.company-detail-top-view .top-left-view .company-tag-view {
  margin-top: 30px;
}
.company-detail-top-view .top-left-view .company-tag-view span {
  display: inline-block;

  font-weight: 400;
  font-size: 18px;
  color: #757575;
  line-height: 24px;
  background-color: #f5f6fa;
  padding: 6px 16px;
  margin-right: 14px;
  margin-top: 14px;
}
.company-detail-top-view .top-right-btn-view {
  text-align: right;
}

.company-detail-top-view .top-right-btn-view .ll-btn-img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.company-detail-top-view .top-right-btn-view .el-button {
  width: 340px;
  height: 62px;
  border-radius: 8px;

  font-weight: 600;
  font-size: 24px;
}
.company-detail-top-view .top-right-btn-view .bot-act-btn-view {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 41px;
}
.company-detail-top-view .top-right-btn-view .bot-act-btn-view .items {
  margin-left: 49px;

  font-weight: 400;
  font-size: 16px;
  color: #757575;
  line-height: 19px;
}

.company-detail-top-view .top-right-btn-view .bot-act-btn-view .items i {
  margin-right: 5px;
}
.company-detail-top-view .top-right-btn-view .bot-act-btn-view .items img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.company-detail-top-view .top-right-btn-view .bot-act-btn-view .items-ispb {
  color: #fe6002;
}
.share-ewm-img-view {
  width: 100%;
  height: 200px;
  text-align: center;
}
.share-ewm-img-view img {
  width: 200px;
  height: 200px;
}
.company-detail-min-view {
  display: flex;
  width: 1480px;
  margin: 0 auto;
}

.company-inf-page .com-sty {
  background-color: #fff;
  margin-top: 15px;
  width: 980px;
  border-radius: 16px;
  padding: 36px;
}

.company-inf-page .com-sty .title-one {
  font-size: 32px;

  font-weight: 600;
  color: #191919;
  line-height: 38px;
}

.company-inf-page .min-ju-view .lc-ts-view {
  font-size: 20px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
  display: flex;
  align-items: center;
}
.company-inf-page .min-ju-view .lc-ts-view img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.company-inf-page .min-ju-view .jb-text {
  font-size: 18px;

  font-weight: 400;
  color: #fe6002;
  margin-top: 10px;
}
.min-ju-view .jb-text a {
  color: #0294fe;
}

.company-inf-page .title-tw-img-view {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  color: #191919;
  line-height: 28px;
  margin-top: 22px;
  margin-bottom: 10px;
}
.company-inf-page .title-tw-img-view img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.company-inf-page .title-sri {
  font-weight: 400;
  font-size: 18px;
  color: #191919;
  line-height: 30px;
  margin-top: 24px;
  white-space: pre-wrap;
}

.company-inf-page .bot-scro-view {
  display: flex;
  align-items: center;
  margin-top: 24px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.company-inf-page .bot-scro-view .scro-img {
  display: inline-block;
  width: 195px;
  height: 126px;
  margin-right: 10px;
  cursor: pointer;
  border: #e6e6e6 2px solid;
}

.company-inf-page .bot-scro-view .icon-arrowhead-left {
  color: white;
  font-size: 30px;
  line-height: 130px;
  margin-left: 8px;
}

.company-inf-page .bot-scro-view .icon-arrowhead-right {
  color: white;
  font-size: 30px;
  line-height: 130px;
  margin-left: 8px;
}
.company-inf-page .slide-left-right-div {
  width: 46px;
  height: 130px;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0.6;
  position: absolute;
  cursor: pointer;
  z-index: 1;
}
.company-inf-page .tmap {
  width: 966px;
  height: 212px;
}

.company-detail-min-view .min-right-view {
  margin-left: 15px;
  margin-top: 15px;
}

.company-detail-min-view .jobdetail-tjjob-view {
  width: 362px;
  background-color: #fff;
  border-radius: 16px;
  padding: 25px 24px;
}

.company-detail-min-view .tj-title-view {
  display: flex;
  align-items: center;
}
.company-detail-min-view .tj-title-view .tj-title-img {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.company-detail-min-view .jobdetail-tjjob-view .tj-view {
  font-size: 22px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
}

.company-detail-min-view .jobdetail-tjjob-view .tj-list .tjitem {
  border-bottom: 1px solid #f5f6fa;
  padding: 20px 0;
  cursor: pointer;
  border-radius: 8px;
}
.company-detail-min-view .jobdetail-tjjob-view .tj-list .tjitem:hover{
  background-color: #fff5f0;
}
.company-detail-min-view .jobdetail-tjjob-view .tj-list .tjitem .tj-top-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.company-detail-min-view
  .jobdetail-tjjob-view
  .tj-list
  .tjitem
  .tj-top-view
  .tj-t1 {
  font-size: 18px;

  font-weight: 600;
  color: #191919;
  /*line-height: 28px;*/
}

.company-detail-min-view
  .jobdetail-tjjob-view
  .tj-list
  .tjitem
  .tj-top-view
  .spar-gz {
  font-size: 16px;

  font-weight: 500;
  color: #fe6002;
  line-height: 19px;
}

.company-detail-min-view .jobdetail-tjjob-view .tj-list .tjitem .com-name {
  font-size: 12px;
  font-weight: 400;
  color: #757575;
  line-height: 14px;
  margin-top: 8px;
}
.company-inf-page .job-tag-s {
  width: 30px;
  height: 15px;
  /*字体样式*/
  font-size: 12px;

  font-weight: 400;
  border-radius: 5px;
  line-height: 15px;
  text-align: center;
  margin-left: 10px;
  color: #54eaab;
  border: 1px solid #54eaab;
}
.company-inf-page .video-cover-img {
  position: absolute;
  top: 35px;
  left: 75px;
}
.company-inf-page .video-open .el-dialog {
  width: 100px;
  background-color: transparent;
}
.company-inf-page .video-open .el-dialog .el-dialog__header {
  display: none;
}
.company-inf-page .min-left-view {
}
</style>